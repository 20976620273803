import { CampaignStatusType } from '@/packages/api/resources/v1/campaign';

export const getCampaignStatus = (key: CampaignStatusType) => {
  switch (key) {
    case 'InProgress':
      return 'In progress';

    case 'ReadyForRelease':
      return 'Ready for release';

    case 'ReleaseFailed':
      return 'Release failed';

    case 'ReleaseInProgress':
      return 'Release in progress';

    case 'Released':
      return 'Released';

    default:
      return 'NotSet';
  }
};
