'use client';

import { useEffect } from 'react';
import { SignalRCampaignStatusChanged, SignalREvent, SignalRPeriodStatusChanged } from './types';
import { useSignalR } from './SignalRContext';
import { Logger } from '@/packages/utils/log';
import { applicationInsights } from '../azure/ApplicationInsights';
import { useRouter } from 'next/navigation';

export const CampaignsSignalR = () => {
  const connection = useSignalR();
  const router = useRouter();

  useEffect(() => {
    const onCampaignStatusChanged = async (payload: SignalRCampaignStatusChanged) => {
      Logger('info', '🔌 SignalR', `SignalRCampaignStatusChanged`, { payload });
      try {
        router.refresh();
      } catch (error: any) {
        Logger('error', '🔌 SignalR', `CampaignStatusChanged`, { error });
        applicationInsights.trackException({ error });
      }
    };

    const onPeriodStatusChanged = async (payload: SignalRPeriodStatusChanged) => {
      Logger('info', '🔌 SignalR', `onPeriodStatusChanged`, { payload });
      try {
        router.refresh();
      } catch (error: any) {
        Logger('error', '🔌 SignalR', `onPeriodStatusChanged`, { error });
        applicationInsights.trackException({ error });
      }
    };

    if (connection) {
      Logger('info', '🔌 SignalR', `Listening to CampaignStatusChanged`);
      connection.on(SignalREvent.CampaignStatusChanged, onCampaignStatusChanged);
      connection.on(SignalREvent.PeriodStatusChanged, onPeriodStatusChanged);

      return () => {
        Logger('info', '🔌 SignalR', `Stop listening to CampaignStatusChanged`);
        connection.off(SignalREvent.CampaignStatusChanged, onPeriodStatusChanged);
        connection.off(SignalREvent.PeriodStatusChanged, onPeriodStatusChanged);
      };
    } else {
      Logger('info', '🔌 SignalR', `Cannot listen to CampaignStatusChanged because SignalR is not connected`, { connection });
    }
  }, [connection, router]);

  return null;
};
