'use client';

import React, { createContext, useContext, ReactNode } from 'react';
import { ConfigBrandDictionaryType, DictionaryKey } from '../api/resources/v1/config/brand';

const DictionaryContext = createContext<ConfigBrandDictionaryType | undefined>(undefined);

export const DictionaryProvider: React.FC<{ value: ConfigBrandDictionaryType; children: ReactNode }> = ({ value, children }) => (
  <DictionaryContext.Provider value={value}>{children}</DictionaryContext.Provider>
);

export const useDictionary = () => {
  const context = useContext(DictionaryContext);
  if (!context) {
    throw new Error('useDictionary must be used within a DictionaryProvider');
  }
  return (key: DictionaryKey, data?: { [key: string]: string }) => {
    const item = context.find(a => a.key === key);
    let value = item ? item.value : '';

    if (data) {
      for (const key in data) {
        if (value.includes(`{${key}}`)) {
          value = value.replace(`{${key}}`, data[key]);
        }
      }
    }

    return value;
  };
};
