'use client';

import React from 'react';
import api from '@/packages/api/api';
import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { useDictionary } from '@/packages/dictionary/DictionaryContext';
import { ContextMenuButton } from '@/packages/ui/ContextMenuButton';
import { IconTypes } from '@/packages/ui/Icons';

type Props = {
  campaignGroupId?: string;
  finalAudioOnly?: boolean;
  campaignGroup: string;
  icon?: IconTypes | undefined;
  label: string;
};

export const DownloadAllAudiosButton: React.FC<Props> = ({ campaignGroup, campaignGroupId, label, icon, finalAudioOnly = false }) => {
  const { setNotification, setContextMenu } = useFloatingUIStore();
  const dictionary = useDictionary();

  const onClick = async () => {
    setContextMenu(null);
    setNotification({
      title: 'Preparing download',
      content: (
        <p>
          <span className="font-bold">{campaignGroup}</span> in progress
        </p>
      ),
    });

    const response = await api.spot.download({ campaignGroupId: String(campaignGroupId), finalAudioOnly });

    if (response.success && response.data) {
      setNotification({
        title: 'Download is ready',
        content: (
          <div className="grid gap-4">
            <p>{campaignGroup} is now ready to be downloaded!</p>

            <a href={response.data.b64} download={`${campaignGroup}-${finalAudioOnly ? 'audio-files' : 'exported'}.zip`}>
              Click to download
            </a>
          </div>
        ),
      });
    }
  };

  return <ContextMenuButton onClick={onClick} label={label} icon={icon} />;
};
