'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Button } from './Button';

type Props = {
  totalPages: number;
  label?: string;
  className?: string;
  perPage: number;
  totalItems: number;
};

export const Pagination: React.FC<Props> = ({ className, totalPages, perPage, totalItems, label }) => {
  const searchParams = useSearchParams();

  const router = useRouter();
  const pathname = usePathname();
  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0;

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLButtonElement;
    const page = String(target.dataset.page);

    const params = new URLSearchParams([...searchParams]);
    params.set('page', page);
    router.push(`${pathname}?${params.toString()}`);
  };

  const onClickPrev = () => {
    const params = new URLSearchParams([...searchParams]);
    params.set('page', String(Number(searchParams.get('page') || '1') - 1));
    router.push(`${pathname}?${params.toString()}`);
  };

  const onClickNext = () => {
    const params = new URLSearchParams([...searchParams]);
    params.set('page', String(Number(searchParams.get('page') || '1') + 1));
    router.push(`${pathname}?${params.toString()}`);
  };

  const generateLabel = () => {
    const curr = currentPage * perPage + 1;
    const pp = (currentPage + 1) * perPage;

    return (
      <p className="text-14 text-blue-200/50">
        {curr}-{pp > totalItems ? totalItems : pp} of {totalItems}
        {label && ` ${label}`}
      </p>
    );
  };

  return (
    <div className={twMerge('grid items-center justify-items-center gap-6', className)}>
      <div className="flex gap-6">
        {totalPages > 1 && <Button disabled={currentPage === 0} onClick={onClickPrev} icon="chevron-left" />}

        <ul className="flex gap-2">
          {Array.from(Array(totalPages).keys()).map(i => {
            const current = i === currentPage;

            return (
              <li key={`page-${i + 1}`}>
                <button
                  onClick={onClick}
                  data-page={i + 1}
                  className={twMerge('flex h-8 w-8 items-center justify-center border-b-2 border-transparent pb-1 text-blue-200/50', current && 'pointer-events-none border-blue-200 text-blue-200')}
                >
                  {i + 1}
                </button>
              </li>
            );
          })}
        </ul>

        {totalPages > 1 && <Button disabled={currentPage === totalPages - 1} onClick={onClickNext} icon="chevron-right" />}
      </div>

      {label && generateLabel()}
    </div>
  );
};
