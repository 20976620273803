export const enum UserRole {
  NotSet = 'NotSet',
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Editor = 'Editor',
  Reviewer = 'Reviewer',
}

export type UserType = {
  fullName: string | undefined;
  userName: string | undefined;
  roles: UserRole[];
  id?: string;
  userId?: string;
  approvalLevels: number[];
  isActive: boolean;
  passwordConfirmed: boolean;
};
