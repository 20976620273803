'use client';

import { Pagination } from '@/packages/ui/Pagination';
import { CampaignTable } from './CampaignTable';
import React from 'react';
import { GetAllCampaignsResponse } from '@/packages/api/resources/v1/campaign';
import { UserType } from '@/app/(pages)/account/types';
import { useDictionary } from '@/packages/dictionary/DictionaryContext';

type Props = {
  campaigns: GetAllCampaignsResponse;
  user: UserType;
};

export const List: React.FC<Props> = ({ campaigns, user }) => {
  const dictionary = useDictionary();

  return (
    <>
      <CampaignTable items={campaigns.items} user={user} />
      <Pagination className="self-end" totalPages={campaigns.totalPages} perPage={campaigns.itemsPerPage} totalItems={campaigns.totalItems} label={dictionary('campaigns.pagination.label')} />
    </>
  );
};
