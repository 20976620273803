'use client';

import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import Link from 'next/link';
import React from 'react';
import { Icon, IconTypes } from './Icons';
import { twMerge } from 'tailwind-merge';

export type ContextMenuButtonProps = {
  href?: string;
  label?: string;
  icon?: IconTypes;
  type?: 'button' | 'submit';
  variant?: 'default' | 'primary';
  labelAlign?: 'left' | 'center';
  onClick?: (event: React.MouseEvent) => void;
};

export const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({ href, icon, label, variant, labelAlign, type = 'button', onClick }) => {
  const { setContextMenu } = useFloatingUIStore();
  const alignKlass = 'items-center justify-between';
  const labelKlass = twMerge(labelAlign === 'center' ? 'text-center' : 'text-left');
  const colorKlass = variant === 'primary' ? 'bg-brand-primary hover:opacity-80 text-white' : 'hover:bg-blue-200/5';
  const klass = twMerge('flex h-16 px-3 text-14 transition-all duration-250', colorKlass, labelKlass, alignKlass);

  const clickHandle = (event: React.MouseEvent) => {
    setContextMenu(null);
    if (onClick) onClick(event);
  };

  if (href) {
    return (
      <Link className={klass} href={href} onClick={clickHandle}>
        {label && <p className="flex-1">{label}</p>}
        {icon && <Icon type={icon} />}
      </Link>
    );
  }

  return (
    <button className={klass} onClick={clickHandle} type={type}>
      {label && <p className="flex-1">{label}</p>}
      {icon && <Icon type={icon} />}
    </button>
  );
};
