import { UserType, UserRole } from '@/app/(pages)/account/types';
import api from '@/packages/api/api';
import { CampaignStatusType, CampaignType } from '@/packages/api/resources/v1/campaign';
import { applicationInsights } from '@/packages/azure/ApplicationInsights';
import { Button } from '@/packages/ui/Button';
import { ContextMenu } from '@/packages/ui/ContextMenu';
import { Icon } from '@/packages/ui/Icons';
import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { Logger } from '@/packages/utils/log';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DownloadAllAudiosButton } from './DownloadAllAudiosButton';
import { StatusPill } from './StatusPill';
import moment from 'moment';
import { useDictionary } from '@/packages/dictionary/DictionaryContext';
import { ContextMenuButton } from '@/packages/ui/ContextMenuButton';

type Props = {
  campaignGroup: string;
  campaignGroupId: string;
  status: CampaignStatusType;
  user: UserType;
  campaigns: Array<CampaignType>;
  tableColumns: Array<{ title: string; size: string }>;
};

export const CampaignTableGroup: React.FC<Props> = ({ campaignGroup, campaignGroupId, campaigns, status, user, tableColumns }) => {
  const totalSpots = campaigns.reduce((a, b) => Number(b.totalAds) + a, 0);
  const router = useRouter();
  const [expanded, setExpanded] = useState(false);
  const { setContextMenu, setNotification, setDialog, setAlert } = useFloatingUIStore();
  const dictionary = useDictionary();
  const gridTemplateColumns = useMemo(() => tableColumns.map(col => col.size).join(' '), [tableColumns]);

  // const generateToolTipContent = (content: string) => <div className="grid [&>*:not(:last-child)]:border-b">{content}</div>;

  const onEdit = (id: string) => {
    router.push(`/campaign/${id}`);
  };

  // const onClickMarkAsReleased = async () => {
  //   setContextMenu(null);
  //   setAlert({ title: 'Info', content: <p>This feature implementation is in progress still</p> });
  // };

  const onClickRelease = (event: React.MouseEvent) => {
    event.stopPropagation();
    setContextMenu(null);

    const confirm = async () => {
      try {
        const response = await api.release.request(campaignGroupId);
        Logger('info', 'Released', 'response', response);

        if (response.success) {
          setNotification({
            title: `${campaignGroup} was released!`,
            content: (
              <div className="grid gap-4">
                <p>
                  The release process for {campaignGroup} started successfully at {moment().format('MMMM Do, YYYY')}
                </p>
              </div>
            ),
          });
        } else {
          setNotification({
            title: `${campaignGroup} - Release failed`,
            content: (
              <div className="grid gap-4">
                <p>{response.message}</p>
              </div>
            ),
          });
        }
      } catch (error: any) {
        Logger('error', 'Release', 'triggering release', { error });
        applicationInsights.trackException({ error }, { context: `Release` });
      }
    };

    setDialog({
      title: 'Please confirm',
      content: <p className="text-14">{`Are you sure you want to release all spots within group ${campaignGroup}?`}</p>,
      confirm,
    });
  };

  const onClickToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={twMerge('grid px-6 py-3 pb-0 transition-all duration-300 ease-out', !expanded && 'hover:bg-slate-200/15')} key={`table-row-${campaignGroup}`}>
      <div className={twMerge('mb-3 grid cursor-pointer items-center px-4 text-blue-200/50', !expanded && 'hover:text-blue-200')} onClick={onClickToggle} style={{ gridTemplateColumns }}>
        <div>
          {campaignGroup ? (
            <Link href={`/campaign/group/${campaignGroupId}`} onClick={event => event.stopPropagation()}>
              {campaignGroup}
            </Link>
          ) : (
            <p>Ungrouped</p>
          )}
        </div>
        <div>{campaigns.length}</div>
        <div className="flex items-center justify-start">
          <StatusPill status={status} />
          {/* <Tooltip trigger={<Button icon="info" variant="transparent" />} content={generateToolTipContent('test')} /> */}
        </div>
        <div>{totalSpots}</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div className="flex items-center justify-end gap-4">
          {!user.roles.includes(UserRole.Reviewer) && /ReadyForRelease|ReleaseFailed|Released/i.test(status) && <Button variant="primary" label="Release" onClick={onClickRelease} />}

          <ContextMenu
            positionX="left"
            positionY="center"
            trigger={<Button variant="transparent" icon="more" />}
            content={
              <div className="grid border-blue-200/20 [&>*:not(:last-child)]:border-b">
                <ContextMenuButton label={dictionary('campaigns.table.menu.group')} href={`/campaign/group/${campaignGroupId}`} />

                {/* {!user.roles.includes(UserRole.Reviewer) && status === 'ReadyForRelease' && (
                  <div className="flex items-center justify-between gap-5 px-2 py-3 text-14 hover:cursor-pointer hover:bg-blue-200/5" onClick={onClickMarkAsReleased}>
                    Mark as released
                  </div>
                )} */}

                <DownloadAllAudiosButton campaignGroup={campaignGroup} campaignGroupId={campaignGroupId} label={dictionary('campaigns.table.menu.download')} icon={`download`} finalAudioOnly={true} />

                <DownloadAllAudiosButton campaignGroup={campaignGroup} campaignGroupId={campaignGroupId} icon={`move-files`} label={'Export'} />
              </div>
            }
          />
        </div>
      </div>

      <AnimatePresence>
        {expanded && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, height: 'auto' }} exit={{ opacity: 0, height: 0 }} className={twMerge('mb-3 grid gap-4 overflow-hidden')}>
            <div className="grid overflow-y-auto rounded-xl [&>*:not(:first-child)]:border-t [&>*:not(:first-child)]:border-blue-200/5">
              {campaigns.map((campaign, index) => (
                <div
                  key={index}
                  onClick={() => onEdit(String(campaign.campaignId))}
                  className="group grid cursor-pointer items-center bg-blue-200/5 px-4 py-3 text-blue-200/50 transition duration-200 hover:bg-blue-200/10 hover:text-blue-200"
                  style={{ gridTemplateColumns }}
                >
                  <div>&nbsp;</div>
                  <div className="truncate pr-2" title={campaign.name}>
                    {campaign.name}
                  </div>
                  <div className="flex justify-start">
                    <StatusPill status={campaign.status as CampaignStatusType} />
                  </div>
                  <div>{campaign.totalAds}</div>
                  <div>{campaign.releaseId}</div>
                  <div title={moment(campaign.createdAt).fromNow()}>{moment(campaign.createdAt).format('MMM DD, YYYY, hh:mm a')}</div>
                  <div title={moment(campaign.modifiedAt).format('MMM DD, YYYY, hh:mm a')}>{moment(campaign.modifiedAt).fromNow()}</div>
                  <div className="flex items-center justify-end">
                    <Icon type="arrow-right" className="-translate-x-2 opacity-0 transition-all duration-200 group-hover:translate-x-0 group-hover:opacity-100" />
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
