import React from 'react';
import { getCampaignStatus } from '@/app/(pages)/(home)/campaign/helpers';
import { twMerge } from 'tailwind-merge';
import { CampaignStatusType } from '@/packages/api/resources/v1/campaign';

type Props = {
  status: CampaignStatusType;
};

export const StatusPill: React.FC<Props> = ({ status }) => {
  const label = getCampaignStatus(status);
  const klass = twMerge(
    'ga-pill self-start',
    status === 'ReadyForRelease' && 'bg-green-100/20 text-green-100',
    status === 'ReleaseFailed' && 'bg-red-600/20 text-red-600',
    status === 'Released' && 'bg-transparent text-blue-200/30 border border-blue-200/30'
  );

  return (
    <p className={klass} title={label}>
      {label}
    </p>
  );
};
