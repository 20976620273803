'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useFloatingUIStore } from '@/packages/ui/floatingUI.store';
import { twMerge } from 'tailwind-merge';

type Props = {
  onClickEdit?: () => void;
  onClickDelete?: () => void;
};

type ContextMenuProps = {
  trigger: React.ReactNode;
  content: React.ReactNode;
  onlyOne?: boolean;
  positionX?: 'left' | 'center' | 'right';
  positionY?: 'bottom' | 'center' | 'top';
  className?: string;
  onUpdate?: (opened: boolean) => void;
};

export const ContextMenu: React.FC<ContextMenuProps> = ({ trigger, content, onUpdate, className, positionX = 'right', positionY = 'bottom' }) => {
  const { setContextMenu, contextMenu } = useFloatingUIStore();

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const target = event.currentTarget as HTMLElement;
    const targetRect = target.getBoundingClientRect();
    const data = {
      x: targetRect.x,
      y: targetRect.top + window.scrollY,
      w: targetRect.width,
      h: targetRect.height,
      content,
      positionX,
      positionY,
      className,
    };

    if (contextMenu) {
      if (onUpdate) onUpdate(false);
      setContextMenu(null);
    }

    setTimeout(
      () => {
        setContextMenu(data);
        if (onUpdate) onUpdate(true);
      },
      contextMenu ? 150 : 0
    );
  };

  return (
    <div className="relative" onClick={onClick}>
      {trigger}
    </div>
  );
};

export type ContextMenuContentProps = {
  x: number;
  y: number;
  w: number;
  h: number;
  className?: string;
  content: React.ReactNode;
  positionX: 'left' | 'center' | 'right';
  positionY: 'bottom' | 'center' | 'top';
};

export const ContextMenuContent = () => {
  const { setContextMenu, contextMenu } = useFloatingUIStore();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState({ top: contextMenu?.y, left: contextMenu?.x });

  useEffect(() => {
    const onResize = () => {
      setContextMenu(null);
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setContextMenu]);

  useEffect(() => {
    const getAnimation = (contextMenu: ContextMenuContentProps) => {
      const contentEl = wrapperRef.current;
      const rect = contentEl?.getBoundingClientRect() || { width: 0, height: 0 };
      const ch = rect.height;
      const cw = rect.width;
      const vw = window.innerWidth;
      const vh = window.innerHeight;

      const padding = 12;
      let posX = 0;
      let posY = 0;

      // checking position X
      switch (contextMenu.positionX) {
        case 'left':
          posX = -cw - padding;
          break;

        case 'right':
          posX = cw + contextMenu.w + padding;

        // center
        default:
          posX = -cw / 2 + contextMenu.w / 2;
          break;
      }

      // checking position Y

      switch (contextMenu.positionY) {
        case 'top':
          posY = -ch - padding;
          break;

        case 'bottom':
          posY = contextMenu.h + padding;
          break;

        case 'center':
          posY = -ch / 2 + contextMenu.h / 2;
          break;

        default:
          posY = contextMenu.h + padding;
          break;
      }

      // checking constrains

      // Adjust for viewport boundaries (X-axis)
      if (contextMenu.x + posX + cw > vw) {
        posX = vw - (contextMenu.x + cw + padding);
      }
      if (contextMenu.x + posX < 0) {
        posX = -contextMenu.x + padding;
      }

      // Adjust for viewport boundaries (Y-axis)
      const normalizedY = contextMenu.y - scrollY;
      if (normalizedY + contextMenu.h + ch + padding > vh) {
        posY = -ch - padding;
      }

      return { top: contextMenu?.y, left: contextMenu?.x, transform: `translate(${posX}px, ${posY}px)`, opacity: 1 };
    };

    if (contextMenu) setAnimation(getAnimation(contextMenu));
  }, [contextMenu]);

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      // setContextMenu(null);
      // document.removeEventListener('click', onClick);

      const target = event.target as HTMLElement;
      const wrapper = wrapperRef.current;

      if (wrapper) {
        if (!wrapper.contains(target)) {
          setContextMenu(null);
          document.removeEventListener('click', onClick);
        }
      }
    };

    if (contextMenu) {
      document.addEventListener('click', onClick);
    } else {
      document.removeEventListener('click', onClick);
    }
  }, [contextMenu, setContextMenu]);

  return (
    <div className={twMerge('shadow-l absolute z-10 grid w-64 overflow-hidden rounded-3xl border bg-white shadow-md', contextMenu?.className)} style={{ opacity: 0, ...animation }}>
      <div ref={wrapperRef}>{contextMenu?.content}</div>
    </div>
  );
};
